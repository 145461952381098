'use client';

import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import MobileSearchModal from '../MobileSearchModal';
import { LanguageSwitcher } from '../../LanguageSwitcher';
import { DeviceType, MenuType } from '@mybestbrands/types';
import styles from './menu.module.scss';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { GENDER } from '@/app/constants';
import Link from 'next/link';
import { getCurrentLocalePath } from '@/app/helpers/locale';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { trackSSTEvent } from '@/app/utils/tracking';

const getSelectedGender = (gender: string) => {
  return [GENDER.MAN, GENDER.WOMAN].includes(gender) ? gender : null;
};

export function MobileMenu(props: {
  gender: string;
  menu: MenuType[] | null | undefined;
  device: DeviceType;
  host: string;
}) {
  const [isMenuModalOpen, setMenuModalState] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const [currentChildCategory, setCurrentChildCategory] = useState<string | null>(null);
  const [currentMenu, setCurrentMenu] = useState<MenuType | null>(null);
  const { t, i18n } = useTranslation('navigation');
  const [currentGender, setCurrentGender] = useState(getSelectedGender(props.gender));
  const [selectedMenus, setSelectedMenus] = useState({
    selectedParent: '',
    selectedChild: '',
  });
  const currentPath = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const localePath = getCurrentLocalePath(i18n.language);

  useEffect(() => {
    const { parent: activeParent, child: activeChild, menu: activeMenu } = getActiveParentAndChild(currentPath);
    const pageTagsSearchParam = searchParams?.get('pageTags');

    const selectedMenus = {
      selectedParent: '',
      selectedChild: pageTagsSearchParam ?? '',
    };

    if (activeParent) selectedMenus.selectedParent = activeParent;
    if (activeChild) selectedMenus.selectedChild = `${localePath}/${activeChild.toLowerCase()}/`;

    if (selectedMenus.selectedChild || selectedMenus.selectedParent) setSelectedMenus(selectedMenus);

    if (activeParent) setCurrentCategory(activeParent);

    if (activeMenu) {
      setCurrentChildCategory(activeChild);
      setCurrentMenu(activeMenu);
    }
  }, []);

  const openMenu = () => {
    setMenuModalState(true);
    trackSSTEvent('burger_menu_open_mobile', null);
  };
  const closeMenu = () => setMenuModalState(false);

  const getActiveParentAndChild = (category: string | null) => {
    const ret = {
      parent: '',
      child: '',
      menu: currentMenu,
    };

    const activeParent = props.menu?.find((item) => `${localePath}${item.url}` === category);

    if (activeParent && activeParent.children.length) {
      ret.parent = activeParent.name;
      return ret;
    }

    if (!activeParent) {
      props.menu?.forEach((item) => {
        const child = item.children?.find((child) => `${localePath}${child.url}` === category);
        if (child) {
          ret.child = child.name;
          ret.parent = item.name;
        }

        if (localePath !== '/it') {
          item.children.forEach((el) => {
            const grChild = el.children?.find((child) => `${localePath}${child.url}` === category);
            if (grChild) {
              ret.menu = el;
              ret.child = el.name;
              ret.parent = item.name;
            }
          });
        }
      });
    }

    return ret;
  };

  const handleClickCategory = (categoryId: string) => {
    setCurrentCategory(categoryId);
  };

  const handleClickChildCategory = (item: MenuType, categoryId: string) => {
    setCurrentMenu(item);
    setCurrentChildCategory(categoryId);
  };

  const handleGoBack = () => setCurrentCategory(null);

  const handleGoBackParent = () => setCurrentChildCategory(null);
  const showGenderButtons = localePath === '/it';

  return (
    <>
      <div onClick={openMenu}>
        <Image src={'/intstatic/icons/hamburger-white.svg'} alt={'Menu Bar'} height={36} width={26} priority />
      </div>
      {isMenuModalOpen && (
        <MobileSearchModal onClose={closeMenu}>
          <div
            className={clsx(
              'bg-white h-100 fixed-top overflow-auto',
              props.device === DeviceType.TABLET ? 'w-50' : 'w-100'
            )}>
            <div className="d-flex aligncenter justify-content-between mt-3 mx-4">
              <div className="d-flex align-items-center">
                <Link href={`${localePath}/`}>
                  <Image height={24} width={24} className="pe-1" src={'/intstatic/icons/home.svg'} alt="home" />
                </Link>
                <LanguageSwitcher device={props.device} host={props.host} />
              </div>
              <Image onClick={closeMenu} src="/intstatic/icons/close.svg" alt="Close" height={14} width={14} />
            </div>
            <div className="bg-body-secondary w-100 mt-3 mb-3" style={{ height: '1px' }}></div>
            <SwitchTransition mode="out-in">
              <CSSTransition
                key={currentCategory !== null ? 'submenu' : 'topMenu'}
                timeout={200}
                classNames={{
                  enter:
                    currentCategory !== null ? styles['menu-list-enter-submenu'] : styles['menu-list-enter-topmenu'],
                  enterActive: styles['menu-list-enter-active'],
                  exit: currentCategory !== null ? styles['menu-list-exit-submenu'] : styles['menu-list-exit-topmenu'],
                  exitActive: currentCategory === null ? styles.exitActiveRight : styles.exitActiveLeft,
                }}
                unmountOnExit>
                <>
                  {!!currentChildCategory && (
                    <ul className={`p-0 m-0 ${styles.menulist}`}>
                      <li className={`px-2 pt-2 pb-1 ${styles.menuitem}`} onClick={handleGoBackParent}>
                        <div className="d-flex align-items-center">
                          <Image
                            src={'/intstatic/icons/black-arrow-left.svg'}
                            alt={'go_to_submenu'}
                            height={20}
                            width={15}
                          />
                          <span className="ps-3 fw-bold">{t(currentChildCategory)}</span>
                        </div>
                      </li>
                      {currentMenu?.children.map((el) => {
                        const pageTags = searchParams?.get('pageTags');
                        let isSelected = pageTags === el.name;
                        if (!isSelected) isSelected = currentPath === `${localePath}${el.url}` && !pageTags;
                        return (
                          <li
                            className={clsx(`px-4 pt-2 pb-1`, styles.menuitem, isSelected ? styles.menuItemActive : '')}
                            key={el.id}
                            onClick={() => {
                              trackSSTEvent('burger_menu_click_mobile', null);
                              router.push(`${localePath}${el.url}`);
                              closeMenu();
                            }}>
                            {el.name === currentCategory ? t('ALL_IN') : t(el.name)}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {!!currentCategory && !currentChildCategory && (
                    <ul className={`p-0 m-0 ${styles.menulist}`}>
                      <li className={`px-2 pt-2 pb-1 ${styles.menuitem}`} onClick={handleGoBack}>
                        <div className="d-flex align-items-center">
                          <Image
                            src={'/intstatic/icons/black-arrow-left.svg'}
                            alt={'go_to_submenu'}
                            height={20}
                            width={15}
                          />
                          <span className="ps-3 fw-bold">{t(currentCategory)}</span>
                        </div>
                      </li>
                      {(props.menu || [])
                        .find((category) => category.name === currentCategory)
                        ?.children?.filter((item) =>
                          currentGender
                            ? item.gender === currentGender ||
                              item.gender === GENDER.NEUTRAL ||
                              item.gender === '' ||
                              item.gender === undefined
                            : item
                        )
                        .map((item) => {
                          const pageTags = searchParams?.get('pageTags');
                          let isSelected = pageTags === item.name;
                          if (!isSelected) isSelected = currentPath === `${localePath}${item.url}` && !pageTags;
                          return (
                            <li
                              className={clsx(
                                `px-4 pt-2 pb-1`,
                                styles.menuitem,
                                isSelected ? styles.menuItemActive : ''
                              )}
                              onClick={() => {
                                trackSSTEvent('burger_menu_click_mobile', null);
                                if (!item.children?.length || localePath === '/it') {
                                  router.push(`${localePath}${item.url}`);
                                  return closeMenu();
                                } else {
                                  handleClickChildCategory(item, item.name);
                                }
                              }}
                              key={item.id}>
                              {item.name === currentCategory ? t('ALL_IN') : t(item.name)}
                              {item.children?.length > 0 && localePath !== '/it' && (
                                <Image
                                  src={'/intstatic/icons/black-arrow-right.svg'}
                                  alt={'go_to_submenu'}
                                  height={15}
                                  width={12}
                                />
                              )}
                            </li>
                          );
                        })}
                    </ul>
                  )}
                  {!currentCategory && (
                    <div className={`${styles.menulist}`} style={{ fontSize: '1.125rem' }}>
                      {showGenderButtons && (
                        <div className="d-flex px-4 mb-3">
                          {[GENDER.WOMAN, GENDER.MAN].map((g) => (
                            <Link
                              key={g}
                              href={`${localePath}/?gender=${g}`}
                              onClick={() => {
                                trackSSTEvent('burger_menu_click_mobile', null);
                                setCurrentGender(g);
                              }}
                              className={clsx(
                                'pe-4',
                                currentGender === g
                                  ? 'text-black fw-bold text-decoration-underline'
                                  : 'text-black-50 text-decoration-none'
                              )}>
                              {t(g.toUpperCase())}
                            </Link>
                          ))}
                        </div>
                      )}
                      <ul className="p-0 m-0">
                        {(props.menu || []).map((category) => {
                          return (
                            <li
                              // className={clsx(`px-4 pt-2 pb-2`, styles.menuitem)}
                              className={clsx(
                                `px-4 pt-2 pb-2`,
                                styles.menuitem,
                                selectedMenus.selectedParent === category.name || currentPath === category.url
                                  ? styles.menuItemActive
                                  : ''
                              )}
                              key={category.name}
                              onClick={() => {
                                trackSSTEvent('burger_menu_click_mobile', null);
                                if (!category.children?.length) {
                                  router.push(`${localePath}${category.url}`);
                                  return closeMenu();
                                } else {
                                  handleClickCategory(category.name);
                                }
                              }}>
                              {category.name}
                              {category.children?.length > 0 && (
                                <Image
                                  src={'/intstatic/icons/black-arrow-right.svg'}
                                  alt={'go_to_submenu'}
                                  height={15}
                                  width={12}
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </MobileSearchModal>
      )}
    </>
  );
}
